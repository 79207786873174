<template>
  <v-app>
    <div v-if="loggedIn">
      <NavigationBarVue />
      <v-main>
        <v-container class="white">
          <router-view />
        </v-container>
      </v-main>
    </div>
    <NotificationMessage />
    <Confirm ref="confirm" />
    <ContentViewer ref="contentViewer" />
  </v-app>
</template>

<script>
import ContentViewer from "./components/ContentViewer.vue";
import Confirm from "./components/ConfirmDialog.vue";
import NavigationBarVue from "./components/NavigationBar.vue";
import NotificationMessage from "./components/NotificationMessage.vue";
import { msalInstance } from "vue-msal-browser";

export default {
  name: "App",
  data() {
    return {
      accounts: [],
      policy: "B2C_1_SignInSignUp",
    };
  },
  async created() {
    const response = await msalInstance.handleRedirectPromise();
    if (response) {
      if (
        response.idTokenClaims["tfp"].toUpperCase() ===
        this.policy.toUpperCase()
      ) {
        await this.handleResponse(response);
      }
    }
    this.accounts = msalInstance.getAllAccounts();
    if (this.accounts.length === 0) {
      const request = {
        scopes: [
          "https://momorocb2c.onmicrosoft.com/40a88bc7-6655-46b8-bbb8-63211e2066d4/User.ReadWrite",
        ],
        prompt: "select_account",
        redirectUri: "/",
      };
      await msalInstance.loginRedirect(request);
    }
    this.selectAccount();
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
    this.$root.$contentViewer = this.$refs.contentViewer.open;
  },
  methods: {
    async handleResponse(response) {
      if (response !== null) {
        this.setAccount(response.account);
      } else {
        this.selectAccount();
      }
    },
    selectAccount() {
      const currentAccounts = msalInstance.getAllAccounts();
      if (currentAccounts.length < 1) {
        return;
      } else if (currentAccounts.length > 1) {
        const accounts = currentAccounts.filter(
          (account) =>
            account.homeAccountId
              .toUpperCase()
              .includes(this.policy.toUpperCase()) &&
            account.idTokenClaims.iss
              .toUpperCase()
              .includes("momorocb2c.b2clogin.com".toUpperCase()) &&
            account.idTokenClaims.aud === "40503424-39cf-4260-b1f7-d6b8da8e942a"
        );
        if (accounts.length > 1) {
          // localAccountId identifies the entity for which the token asserts information.
          if (
            accounts.every(
              (account) => account.localAccountId === accounts[0].localAccountId
            )
          ) {
            // All accounts belong to the same user
            this.setAccount(accounts[0]);
          } else {
            // Multiple users detected. Logout all to be safe.
            this.logout();
          }
        } else if (accounts.length === 1) {
          this.setAccount(accounts[0]);
        }
      } else if (currentAccounts.length === 1) {
        this.setAccount(currentAccounts[0]);
      }
    },
    setAccount(account) {
      msalInstance.setActiveAccount(account);
    },
  },
  computed: {
    loggedIn() {
      return this.accounts.length > 0;
    },
  },
  components: {
    Confirm,
    ContentViewer,
    NavigationBarVue,
    NotificationMessage,
  },
};
</script>
