<template>
  <v-app-bar app color="primary" flat>
    <router-link to="/">
      <img
        class="mr-3"
        :src="require('@/assets/logo-white.png')"
        height="50"
        contain />
    </router-link>
    <v-btn text plain title="Sign-out" to="/" class="white--text"> Home</v-btn>
    <v-spacer></v-spacer>
    <div class="d-none d-sm-flex">
      <v-btn text title="Sign-out" disabled class="white--text">Feedback</v-btn>
      <v-btn text title="Sign-out" to="/help" class="white--text">Help</v-btn>
    </div>
    <div class="d-none d-sm-flex">
      <v-btn text title="Sign-out" class="white--text" @click="signOut"
        ><v-icon>mdi-logout-variant </v-icon>
        {{ loggedInAccount.username }}</v-btn
      >
    </div>
    <div class="d-sm-none">
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn large icon color="white" v-bind="attrs" v-on="on">
            <v-icon>mdi-menu </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item disabled>
            <v-list-item-title> Feedback </v-list-item-title>
          </v-list-item>
          <v-list-item to="/help">
            <v-list-item-title> Help </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn text title="Sign-out" class="white--text" @click="signOut">
                <v-icon>mdi-logout-variant </v-icon>
                {{ loggedInAccount.username }}
              </v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { msalInstance } from "vue-msal-browser";

export default {
  methods: {
    signOut() {
      this.$msal.logoutRedirect();
    },
  },
  computed: {
    loggedInAccount() {
      return msalInstance.getActiveAccount();
    },
  },
};
</script>
