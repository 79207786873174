<template>
  <v-row dense no-gutters align="center" class="grey lighten-3">
    <!-- <v-col cols="12">{{ song }}</v-col> -->
    <v-col cols="12" sm="2" lg="1" class="text-center">
      <v-btn
        :disabled="controlsEnabled"
        v-if="showAdd && !showRemove"
        @click="$emit('add', song)"
        color="primary"
        tile
        small
        block
        >Select</v-btn
      >
      <v-btn
        v-if="!showAdd && showRemove"
        :disabled="controlsEnabled"
        @click="$emit('remove', song)"
        color="primary"
        tile
        small
        block
        >Remove</v-btn
      >
    </v-col>
    <v-col cols="12" sm="5" lg="6">
      <span class="ml-2">{{ song.durationSeconds | formatDuration }}</span> |
      <span>{{ songName }} </span>
    </v-col>
    <v-col cols="12" sm="5" lg="5">
      <audio
        class="py-0 my-0"
        controls
        style="width: 100%"
        controlsList="nodownload"
        :src="song.absolutePath"
        preload="none">
        Your browser does not support the audio element.
      </audio>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    song: {
      require: true,
    },
    showRemove: {
      require: false,
      default: false,
    },
    showAdd: {
      require: false,
      default: true,
    },
    controlsEnabled: {
      require: false,
      default: false,
    },
  },
  computed: {
    songName() {
      let file = this.song.absolutePath.substring(
        this.song.absolutePath.lastIndexOf("/") + 1
      );

      return file.substring(0, file.lastIndexOf("."));
    },
  },
};
</script>
