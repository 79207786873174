<template>
  <div>
    <v-row class="mx-1">
      <v-col cols="12">
        <div class="text-h5">Project songs</div>
        <v-sheet max-height="400" style="overflow-y: scroll" class="mt-2">
          <SongRowComponent
            class="mb-2"
            v-for="(song, i) in project.songs"
            :key="i"
            @remove="projectSongRemoved(i)"
            :showAdd="false"
            :showRemove="true"
            :song="song" />
          <LoadingOverlay
            :loading="projectLoading || songsLoading"
            :progress="false" />
        </v-sheet>
      </v-col>
    </v-row>
    <v-row class="mx-1 mt-0">
      <v-col cols="12">
        <div class="text-h5">Song library</div>
        <v-select
          class="mt-5"
          filled
          outlined
          :loading="songCategoriesLoading"
          :disabled="songCategoriesLoading"
          :items="songCategoriesFormatted"
          @change="selectedSongCategoryChanged"
          v-model="selectedSongCategory"
          item-text="name"
          item-value="id"
          hide-details
          return-object
          label="Song Categories">
        </v-select>
      </v-col>
      <v-col cols="12">
        <v-sheet min-height="400" style="overflow-y: scroll">
          <SongRowComponent
            class="mb-2"
            v-for="(song, i) in songs"
            :key="i"
            :showAdd="true"
            @add="projectSongAdded"
            :showRemove="false"
            :song="song" />
          <div v-if="songs.length === 0" class="text-center">
            No songs found
          </div>
          <LoadingOverlay
            :loading="projectLoading || songsLoading"
            :progress="false" />
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import SongRowComponent from "@/components/SongRowComponent.vue";
import { mapActions, mapState } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      selectedSongCategory: {
        id: null,
        absolutePath: null,
        name: "Top 100",
      },
    };
  },
  async created() {
    try {
      await this.getSongCategoriesAsync();
      await this.getTopSongsAsync();
    } catch {
      alert("ProjectSongsView error");
    }
  },
  methods: {
    ...mapActions("songCategories", ["getSongCategoriesAsync"]),
    ...mapActions("songs", ["getTopSongsAsync", "getSongsAsync"]),
    ...mapActions("projects", ["setProjectSongsAsync"]),
    async selectedSongCategoryChanged({ id, name }) {
      if (!id) {
        await this.getTopSongsAsync();
      } else {
        await this.getSongsAsync(name);
      }
    },
    async projectSongRemoved(index) {
      let projectSongs = [...this.project.songs];
      projectSongs.splice(index, 1);

      await this.setProjectSongsAsync({
        id: this.id,
        projectSongs,
      });
    },
    async projectSongAdded(song) {
      let projectSongs = [...this.project.songs];
      projectSongs.push(song);

      await this.setProjectSongsAsync({
        id: this.id,
        projectSongs,
      });
    },
  },
  computed: {
    ...mapState("songCategories", ["songCategories", "songCategoriesLoading"]),
    ...mapState("songs", ["songs", "songsLoading"]),
    ...mapState("projects", ["project", "projectLoading"]),
    songCategoriesFormatted() {
      const systemCategories = [
        { id: null, absolutePath: null, name: "Top 100" },
      ];

      return systemCategories.concat(this.songCategories);
    },
  },
  components: {
    SongRowComponent,
    LoadingOverlay,
  },
};
</script>
