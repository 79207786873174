<template>
  <v-card>
    <v-card-title>
      {{ deceasedPersonFullName }}
      <span v-if="project.deceasedPerson.deathDate">
        <span>,</span>
        {{ project.deceasedPerson.birthDate | formatDate }} -
        {{ project.deceasedPerson.deathDate | formatDate }}</span
      >
    </v-card-title>
    <v-card-subtitle>
      Project {{ project.id }} | {{ project.status }}
      <v-divider></v-divider>
    </v-card-subtitle>
    <v-card-text>
      <v-row dense align="center">
        <v-col cols="12" sm="4" md="3">
          <v-btn
            large
            block
            class="my-1"
            color="primary"
            tile
            :disabled="assetKeyLoading || project.status !== 'Active'"
            @click="$refs.fileBrowser.click()"
            >Upload Assets</v-btn
          >
          <input
            type="file"
            accept="image/*,video/*"
            style="display: none"
            ref="fileBrowser"
            multiple
            @change="selectedFilesChanged" />
        </v-col>
      </v-row>

      <v-sheet min-height="500" style="position: relative">
        <v-row dense no-gutters align="center" class="grey lighten-4 mb-2">
          <v-col cols="12" v-for="(asset, i) in assetsFormatted" :key="i">
            <v-row dense no-gutters align="center">
              <v-col cols="12" sm="3" md="2">
                <v-row
                  dense
                  no-gutters
                  align="center"
                  class="grey lighten-4 mb-2">
                  <v-col cols="2" class="text-center">
                    <v-btn x-large icon disabled>
                      <v-icon>mdi-comment-edit-outline </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="10">
                    <div class="mx-2 py-1">
                      <v-img
                        style="border-radius: 5px"
                        v-if="asset.contentType.startsWith('image/')"
                        class="mx-auto"
                        :lazy-src="require('@/assets/placeholder-image.jpg')"
                        :max-height="90"
                        :width="'100%'"
                        :src="asset.absolutePath + assetKey.key"
                        :aspect-ratio="1.7778"></v-img>
                      <div v-else style="width: 100%" class="text-center">
                        <v-icon size="80" color="red"> mdi-youtube </v-icon>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="11" sm="9" md="10">
                <v-row dese>
                  <v-col cols="9" md="10">
                    <div class="ml-4">
                      <div class="subtitle-2">{{ asset.file }}</div>
                    </div>
                  </v-col>
                  <v-col cols="3" md="2" class="text-right">
                    <v-btn
                      color="red"
                      class="mr-2"
                      outlined
                      :disabled="assetsLoading || project.status !== 'Active'"
                      @click="removeProjectAsset(asset)"
                      >Remove</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <LoadingOverlay
          :loading="assetsLoading"
          :absolute="true"
          :opacity="0.35"
          :progress="false" />
      </v-sheet>
    </v-card-text>
  </v-card>
</template>

<script>
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import { msalInstance } from "vue-msal-browser";
import { mapActions, mapState } from "vuex";

export default {
  props: ["id"],
  data() {
    return {
      contributor: undefined,
      selectedAssets: [],
    };
  },
  async created() {
    try {
      this.contributor = msalInstance.getActiveAccount().username;
    } catch {
      alert("Failed to get user account id.");
    }

    if (this.contributor) {
      await this.getAssetKeyAsync(this.id);
      await this.listAssetsByHierarchyAsync({
        id: this.id,
        contributor: this.contributor,
      });
    }
  },
  methods: {
    ...mapActions("assets", [
      "getAssetKeyAsync",
      "listAssetsByHierarchyAsync",
      "uploadAssetsAsync",
      "removeAssetsAsync",
    ]),
    ...mapActions("projects", ["removeProjectAssetsAsync"]),
    async selectedFilesChanged(event) {
      const filesWithMetadata = [];

      for (const file of event.target.files) {
        let duration = await new Promise((resolve, reject) => {
          try {
            if (file.type.startsWith("image/")) {
              resolve(6);
            } else if (file.type.startsWith("video/")) {
              let video = document.createElement("video");
              video.preload = "metadata";

              video.onloadedmetadata = function () {
                resolve(this.duration);
              };

              video.onerror = function () {
                reject("Invalid video. Please select a video file.");
              };

              video.src = window.URL.createObjectURL(file);
            } else {
              reject(`File ${file.name} type ${file.type} is not supported.`);
            }
          } catch (error) {
            reject("Could not get asset duration.");
          }
        });

        let metadata = {
          durationSeconds: duration,
          segments: [],
          contributor: this.contributor,
        };

        filesWithMetadata.push({ file, metadata });
      }

      this.uploadAssetsAsync({
        id: this.id,
        filesWithMetadata,
        contributor: this.contributor,
      });

      this.$refs.fileBrowser.value = null;
    },
    async removeProjectAsset(asset) {
      await this.removeAssetsAsync({
        id: this.id,
        assets: [asset],
      });
    },
  },
  computed: {
    ...mapState("projects", ["project"]),
    ...mapState("assets", [
      "assets",
      "assetKey",
      "assetKeyLoading",
      "assetsLoading",
    ]),
    deceasedPersonFullName() {
      const deceasedPerson = this.project.deceasedPerson;
      let title = `${deceasedPerson.firstName} ${
        deceasedPerson.middleName != null ? deceasedPerson.middleName : ""
      } ${deceasedPerson.lastName}`;
      return title;
    },
    assetsFormatted() {
      return this.assets.map((x) => {
        const file = x.absolutePath.substring(
          x.absolutePath.lastIndexOf("/") + 1
        );
        return { file, ...x };
      });
    },
  },
  components: {
    LoadingOverlay,
  },
};
</script>
