<template>
  <div>
    <div v-if="contentType.startsWith('image/')">
      <v-img
        class="white--text align-end"
        :lazy-src="require('@/assets/placeholder-image.jpg')"
        :src="contentSrc"
        :height="height"
        :aspect-ratio="1.7778">
        <div class="text-right">
          <v-icon
            @click="open"
            large
            color="white"
            class="mr-1 mb-1"
            title="Enlarge"
            >mdi-loupe
          </v-icon>
        </div>
      </v-img>
    </div>
    <div v-else-if="contentType.startsWith('video/')">
      <v-img
        class="white--text align-end"
        :lazy-src="require('@/assets/placeholder-image.jpg')"
        :src="require('@/assets/video-placeholder.jpg')"
        :height="height"
        :aspect-ratio="1.7778">
        <div class="text-right">
          <v-icon
            @click="open"
            large
            color="white"
            class="mr-1 mb-1"
            title="Enlarge"
            >mdi-loupe
          </v-icon>
        </div>
      </v-img>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contentType: {
      require: true,
    },
    contentSrc: {
      require: true,
      default: undefined,
    },
    height: {
      require: false,
      default: "125px",
    },
  },
  methods: {
    open() {
      this.$root.$contentViewer(this.contentSrc, this.contentType);
    },
  },
};
</script>
