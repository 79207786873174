<template>
  <v-row justify="center">
    <v-col cols="10">
      <v-card color="black">
        <v-card-text class="mx-0 px-0">
          <video
            controls
            preload="metadata"
            style="width: 100%"
            autoplay
            :src="helpVideoSrc">
            Your browser does not support the video tag.
          </video>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  computed: {
    helpVideoSrc() {
      return require("@/assets/help-video converted.mp4");
    },
  },
};
</script>
