<template>
  <v-row dense class="text-center grey lighten-3 mx-0 px-0" align="center">
    <v-col cols="12" md="3">
      <v-btn
        plain
        :disabled="initialized"
        :to="`/projects/${project.id}`"
        class="navigation"
        ><v-icon color="primary">mdi-numeric-1-circle </v-icon>

        <span class="ml-1"
          >Assets |
          <span :class="[assetDurationMessage.color]">{{
            projectDuration.projectAssets | formatDuration
          }}</span>
          <v-icon
            v-if="assetDurationMessage.icon"
            :class="[assetDurationMessage.color]"
            >{{ assetDurationMessage.icon }}</v-icon
          >
          / {{ project.configuration.projectDuration | formatDuration }}</span
        ></v-btn
      >
    </v-col>
    <v-col cols="12" md="3"
      ><v-btn
        plain
        :disabled="initialized"
        :to="`/projects/${project.id}/songs`"
        class="navigation"
        ><v-icon color="primary">mdi-numeric-2-circle </v-icon>

        <span class="ml-1"
          >Songs |
          <span :class="[songDurationMessage.color]">{{
            projectDuration.songs | formatDuration
          }}</span>
          <v-icon
            v-if="songDurationMessage.icon"
            :class="[songDurationMessage.color]"
            >{{ songDurationMessage.icon }}</v-icon
          >
          / {{ project.configuration.projectDuration | formatDuration }}</span
        >
      </v-btn></v-col
    >
    <v-col cols="12" md="3"
      ><v-btn
        plain
        :disabled="initialized"
        :to="`/projects/${project.id}/details`"
        class="navigation"
        ><v-icon color="primary">mdi-numeric-3-circle </v-icon>
        <span class="ml-1">Details</span></v-btn
      ></v-col
    >
    <v-col cols="12" md="3"
      ><v-btn
        plain
        :disabled="initialized"
        :to="`/projects/${project.id}/review`"
        class="navigation"
        ><v-icon color="primary">mdi-numeric-4-circle </v-icon>
        <span class="ml-1">Review & Submit</span></v-btn
      ></v-col
    >
  </v-row>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: ["initialized"],
  data() {
    return {};
  },
  computed: {
    ...mapState("projects", ["project"]),
    ...mapGetters("projects", ["projectDuration"]),
    assetDurationMessage() {
      const { projectDuration } = this.project.configuration;
      if (this.projectDuration.projectAssets <= projectDuration) {
        return { color: "primary--text font-weight-bold", icon: null };
      } else {
        return {
          color: "red--text font-weight-bold",
          icon: "mdi-alert-circle-outline",
        };
      }
    },
    songDurationMessage() {
      const { projectDuration } = this.project.configuration;
      if (this.projectDuration.songs <= projectDuration) {
        return { color: "primary--text font-weight-bold", icon: null };
      } else {
        return {
          color: "red--text font-weight-bold",
          icon: "mdi-alert-circle-outline",
        };
      }
    },
  },
};
</script>

<style lang="css" scoped>
>>> .navigation .v-btn__content {
  opacity: 1 !important;
}
</style>
