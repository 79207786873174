<template>
  <v-card dense flat>
    <v-card-text class="ma-0 pa-0">
      <v-row class="mx-2" dense>
        <v-col cols="12">
          <div class="text-h5">Review & Submit</div>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12">
          <v-row dense>
            <v-col cols="12" lg="1" class="grey lighten-4"></v-col>
            <v-col cols="12" lg="11">
              <v-btn
                @click="submitProject"
                :disabled="!submitErrors.canSubmit"
                class="ma-2"
                x-large
                color="primary"
                tile
                >Submit Project</v-btn
              >
              <div
                class="pa-2 white--text mb-2 mx-2"
                :class="[error.color]"
                v-for="(error, i) in submitErrors.errors"
                :key="i">
                <v-icon class="mr-2" color="white"
                  >mdi-alert-circle-outline </v-icon
                >{{ error.message }}
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
          <v-row dense class="mt-2">
            <v-col cols="12" lg="1" class="grey lighten-4">
              <div class="body2 mx-1 mt-3">Assets</div>
            </v-col>
            <v-col cols="12" lg="11">
              <v-simple-table>
                <template v-slot:default>
                  <tbody class="caption">
                    <tr>
                      <td
                        class="primary--text subtitle-1 font-weight-medium"
                        colspan="2">
                        Timeline
                      </td>
                    </tr>
                    <tr
                      v-for="projectAsset in projectAssetsFormatted"
                      :key="projectAsset.name">
                      <td width="50%">{{ projectAsset.file }}</td>
                      <td width="50%">
                        {{
                          projectAsset.durationFormatted.start | secondsToHHMMSS
                        }}
                        -
                        {{
                          projectAsset.durationFormatted.end | secondsToHHMMSS
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td width="50%">&nbsp;</td>
                      <td width="50%" class="font-weight-bold">
                        Total duration:
                        {{ projectDuration.projectAssets | formatDuration }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row dense class="mt-2">
            <v-col cols="12" lg="1" class="grey lighten-4">
              <div class="body2 mx-1 mt-3">Songs</div>
            </v-col>
            <v-col cols="12" lg="11">
              <v-simple-table>
                <template v-slot:default>
                  <tbody class="caption">
                    <tr>
                      <td
                        class="primary--text subtitle-1 font-weight-medium"
                        colspan="2">
                        Selected songs
                      </td>
                    </tr>
                    <tr v-for="(song, i) in projectSongsFormatted" :key="i">
                      <td width="50%">#{{ i + 1 }} {{ song.file }}</td>
                      <td width="50%">
                        {{ song.durationSeconds | formatDuration }}
                      </td>
                    </tr>
                    <tr>
                      <td width="50%">&nbsp;</td>
                      <td width="50%" class="font-weight-bold">
                        Total duration:
                        {{ projectDuration.songs | formatDuration }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row dense class="mt-2">
            <v-col cols="12" lg="1" class="grey lighten-4">
              <div class="body2 mx-1 mt-3">Project details</div>
            </v-col>
            <v-col cols="12" lg="11" class="mt-3">
              <v-simple-table>
                <template v-slot:default>
                  <tbody class="caption">
                    <tr>
                      <td
                        class="primary--text subtitle-1 font-weight-medium"
                        colspan="2">
                        Client Information
                      </td>
                    </tr>
                    <tr>
                      <td width="50%" class="primary--text">First name</td>
                      <td width="50%">
                        {{ project.client.firstName }}
                      </td>
                    </tr>
                    <tr>
                      <td class="primary--text">Middle name</td>
                      <td>{{ project.client.middleName }}</td>
                    </tr>
                    <tr>
                      <td class="primary--text">Last name</td>
                      <td>{{ project.client.lastName }}</td>
                    </tr>
                    <tr>
                      <td class="primary--text">Phone number</td>
                      <td>{{ project.client.phoneNumber }}</td>
                    </tr>
                    <tr>
                      <td class="primary--text">Email address</td>
                      <td>{{ project.client.emailAddress }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-divider></v-divider>
              <v-simple-table>
                <template v-slot:default>
                  <tbody class="caption">
                    <tr>
                      <td
                        class="primary--text subtitle-1 font-weight-medium"
                        colspan="2">
                        Deceased person information
                      </td>
                    </tr>
                    <tr>
                      <td width="50%" class="primary--text">First name</td>
                      <td width="50%">
                        {{ project.deceasedPerson.firstName }}
                      </td>
                    </tr>
                    <tr>
                      <td class="primary--text">Middle name</td>
                      <td>{{ project.deceasedPerson.middleName }}</td>
                    </tr>
                    <tr>
                      <td class="primary--text">Last name</td>
                      <td>{{ project.deceasedPerson.lastName }}</td>
                    </tr>
                    <tr>
                      <td class="primary--text">Birth date</td>
                      <td>
                        {{ project.deceasedPerson.birthDate | formatDate }}
                      </td>
                    </tr>
                    <tr>
                      <td class="primary--text">Death date</td>
                      <td>
                        {{ project.deceasedPerson.deathDate | formatDate }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <LoadingOverlay
      :loading="projectLoading"
      :absolute="false"
      :opacity="0.35"
      :text="`Submitting project...`"
      :progress="true" />
  </v-card>
</template>

<script>
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      // percentage - 0.9 = 90%
      minimumRequiredSongDuration: 0.9,
    };
  },
  methods: {
    ...mapActions("projects", ["submitProjectAsync"]),
    async submitProject() {
      if (
        await this.$root.$confirm(
          "Submit project?",
          "Are you sure you want to submit project?",
          [
            "I understand that after project has been submitted, the order cannot be changed and new files cannot be added.",
          ],
          "primary"
        )
      ) {
        await this.submitProjectAsync(this.id);
      }
    },
  },
  computed: {
    ...mapState("projects", ["project", "projectLoading"]),
    ...mapGetters("projects", ["projectDuration"]),
    projectAssetsFormatted() {
      let projectAssetsFormatted = this.project.assets.map((x) => {
        const file = x.absolutePath.substring(
          x.absolutePath.lastIndexOf("/") + 1
        );

        let durationFormatted = { start: 0, end: 0, durationSeconds: 0 };

        if (x.contentType.startsWith("image")) {
          durationFormatted.durationSeconds = x.durationSeconds;
        } else if (x.contentType.startsWith("video")) {
          if (x.segments.length > 0) {
            for (const segment of x.segments) {
              durationFormatted.durationSeconds += segment.end - segment.start;
            }
          } else {
            durationFormatted.durationSeconds = x.durationSeconds;
          }
        } else {
          durationFormatted.durationSeconds = "durationError";
        }

        return { file, durationFormatted, ...x };
      });

      return projectAssetsFormatted.map((x, i) => {
        if (i === 0) {
          x.durationFormatted.end = x.durationSeconds;
        } else {
          x.durationFormatted.start =
            projectAssetsFormatted[i - 1].durationFormatted.end;
          x.durationFormatted.end =
            x.durationFormatted.start + x.durationFormatted.durationSeconds;
        }

        return x;
      });
    },
    projectSongsFormatted() {
      return this.project.songs.map((x) => {
        let file = x.absolutePath.substring(
          x.absolutePath.lastIndexOf("/") + 1
        );

        file = file.substring(0, file.lastIndexOf("."));

        return { file, ...x };
      });
    },
    submitErrors() {
      const durationFormatted = (seconds) =>
        new Date(seconds * 1000).toISOString().substring(11, 19);

      let canSubmit = [];

      let result = {
        errors: [],
        canSubmit: true,
      };
      const { projectDuration } = this.project.configuration;
      const { projectAssets, songs } = this.projectDuration;

      if (projectAssets > projectDuration) {
        result.errors.push({
          message: `Project asset duration (${durationFormatted(
            projectAssets
          )}) is greater than configured allowed duration (${durationFormatted(
            projectDuration
          )}).`,
          color: "red",
        });
        canSubmit.push(false);
      }

      if (songs > projectDuration) {
        result.errors.push({
          message: `Project song duration (${durationFormatted(
            songs
          )}) is greater than configured allowed duration (${durationFormatted(
            projectDuration
          )}). Selected songs will be faded out.`,
          color: "warning",
        });
        canSubmit.push(true);
      }

      if (songs < projectDuration * this.minimumRequiredSongDuration) {
        result.errors.push({
          message: `Project song duration (${durationFormatted(
            songs
          )}) is less than allowed minimum duration (${durationFormatted(
            projectDuration * this.minimumRequiredSongDuration
          )}). Select additional songs so that minimum song duration requirement is met.`,
          color: "warning",
        });
        canSubmit.push(false);
      }

      result.canSubmit = canSubmit.every((x) => x);

      return result;
    },
  },
  components: {
    LoadingOverlay,
  },
};
</script>
