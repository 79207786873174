import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";

// import { msalPlugin } from "./plugins/msal-browser";
// import { msalInstance } from "./plugins/msal-browser-config";
// import { EventType } from "@azure/msal-browser";

// const accounts = msalInstance.getAllAccounts();
// if (accounts.length > 0) {
//   msalInstance.setActiveAccount(accounts[0]);
// }
// msalInstance.addEventCallback((event) => {
//   if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
//     const payload = event.payload;
//     const account = payload.account;
//     msalInstance.setActiveAccount(account);
//   }
// });

// Vue.use(msalPlugin, msalInstance);

import { default as msalPlugin } from "vue-msal-browser";
import { msalConfig } from "./plugins/msal-browser-config";

Vue.use(msalPlugin, msalConfig);

import BlobClient from "./services/storage";
import "./filters/durationFilter";

const blobClient = new BlobClient();
Vue.use(blobClient);
Vue.prototype.$blobClient = blobClient;

import VueMask from "v-mask";
Vue.use(VueMask);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
