import store from "@/store";

const success = (message) => {
  store.dispatch("notifications/success", message);
};

const error = (message) => {
  store.dispatch("notifications/error", message);
};

const exception = (exception) => {
  console.log("**************************************************");

  // Error thrown by Server
  if (exception.response) {
    if (exception.response.data && exception.response.data.error) {
      store.dispatch("notifications/error", exception.response.data.error);
    } else {
      store.dispatch("notifications/error", exception.message);
    }
  } else if (exception.request) {
    store.dispatch("notifications/error", exception.message);
  } else {
    store.dispatch("notifications/error", "Unhandled exception.");
  }

  console.log("**************************************************");
};

export { success, error, exception };
