<template>
  <v-sheet height="90vh" class="grey lighten-4">
    <v-row class="text-center fill-height" align="center">
      <v-col cols="12">
        <v-icon
          v-if="project.status === 'Approved'"
          color="primary"
          style="font-size: 180px"
          >mdi-checkbox-marked-outline
        </v-icon>
        <v-icon
          v-else-if="project.status === 'Rejected'"
          color="red"
          style="font-size: 180px"
          >mdi-alpha-x-circle
        </v-icon>
        <div class="text-h3">Project Video {{ project.status }}!</div>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState("projects", ["project"]),
  },
};
</script>
