const initialState = {
  notifications: [],
};

const state = { ...initialState };

const timeout = 10;
const uid = () => Math.random().toString(36).slice(2, 7);

const actions = {
  async success({ commit }, message) {
    const notification = { message, color: "primary", id: uid() };
    commit("addNotification", notification);

    setTimeout(() => {
      commit("removeNotification", notification.id);
    }, timeout * 1000);
  },
  async error({ commit }, message) {
    const notification = { message, color: "red", id: uid() };
    commit("addNotification", notification);

    setTimeout(() => {
      commit("removeNotification", notification.id);
    }, timeout * 1000);
  },
};

const mutations = {
  addNotification: (state, notification) => {
    state.notifications.push(notification);
  },
  removeNotification: (state, id) => {
    const index = state.notifications.findIndex((x) => x.id === id);

    if (index === -1) return;

    state.notifications.splice(index, 1);
  },
};

const getters = {
  notifications: (state) => state.notifications,
};

export default {
  state,
  actions,
  mutations,
  getters,
  namespaced: true,
};
