import { success } from "@/helpers/notificationsHandler";
import http from "../../services/http";

const initialState = {
  projects: [],
  projectsLoading: false,
  project: {
    id: undefined,
    assets: [],
    songs: [],
    configuration: {
      projectDuration: 0,
    },
  },
  projectLoading: false,
};

const state = { ...initialState };

const actions = {
  async getProjectsAsync({ commit }) {
    try {
      commit("setProjectsLoading", true);
      const { data } = await http.get("/projects");
      commit("setProjects", data);
    } catch {
      console.log("placeholder error getProjectsAsync");
    } finally {
      commit("setProjectsLoading", false);
    }
  },
  async getProjectAsync({ commit }, id) {
    try {
      commit("setProjectLoading", true);
      const { data } = await http.get(`/projects/${id}`);
      commit("setProject", data);
    } catch {
      commit("setProject", { ...initialState.project });
    } finally {
      commit("setProjectLoading", false);
    }
  },
  async addProjectAssetsAsync({ commit }, { id, assets }) {
    try {
      commit("setProjectLoading", true);
      const { data } = await http.put(`/projects/${id}/assets`, assets);
      commit("setAssets", data);
      success(`Successfully added ${assets.length} project assets.`);
    } catch (error) {
      console.log("placeholder error getProjectsAsync");
    } finally {
      commit("setProjectLoading", false);
    }
  },
  async changeProjectAssetOrderAsync({ commit, state }, { id, assets }) {
    const originalOrder = [...state.project.assets];
    try {
      commit("setAssets", assets);
      commit("setProjectLoading", true);
      const assetIds = assets.map((x) => x.id);
      await http.patch(`/projects/${id}/assets`, assetIds);
      success("Order project asset order.");
    } catch (error) {
      console.error("changeProjectAssetOrderAsync", error);
      commit("setAssets", originalOrder);
    } finally {
      commit("setProjectLoading", false);
    }
  },
  async removeProjectAssetsAsync({ commit }, { id, assets }) {
    try {
      commit("setProjectLoading", true);
      const assetIds = assets.map((x) => x.id);
      const { data } = await http.patch(
        `/projects/${id}/assets/removeProjectAssets`,
        assetIds
      );
      commit("setAssets", data);
      success(`Removed ${assets.length} project assets.`);
    } catch (error) {
      console.log("placeholder error removeProjectAssetsAsync");
    } finally {
      commit("setProjectLoading", false);
    }
  },
  async updateProjectAssetAsync(
    { commit },
    { id, projectAsset, projectAssetUpdate }
  ) {
    const updateFormatted = {
      id,
      projectAssetId: projectAsset.id,
      comments: projectAssetUpdate.comments,
      segments: projectAssetUpdate.segments,
    };

    try {
      commit("setProjectLoading", true);
      const { data } = await http.post(
        `/projects/${id}/assets/updateProjectAsset`,
        updateFormatted
      );
      commit("updateProjectAsset", data);
      success(`Successfully updated project asset!`);
    } catch (error) {
      console.log("placeholder error removeProjectAssetsAsync");
    } finally {
      commit("setProjectLoading", false);
    }
  },
  async setProjectSongsAsync({ commit }, { id, projectSongs }) {
    try {
      commit("setProjectLoading", true);
      const { data } = await http.put(`/projects/${id}/songs`, projectSongs);
      commit("setProjectSongs", data);
    } catch (error) {
      console.log("placeholder error getProjectsAsync");
    } finally {
      commit("setProjectLoading", false);
    }
  },
  async updateClientAsync({ commit }, { id, client }) {
    try {
      commit("setProjectLoading", true);
      const { data } = await http.patch(`/projects/${id}`, client);
      commit("updateClient", data);
      success("Client information updated.");
    } catch (e) {
      console.log(e);
    } finally {
      commit("setProjectLoading", false);
    }
  },
  async submitProjectAsync({ commit }, id) {
    try {
      commit("setProjectLoading", true);
      await http.put(`/projects/${id}`);
      commit("setProjectStatus", "Submitted");
    } catch (e) {
      console.log(e);
    } finally {
      commit("setProjectLoading", false);
    }
  },
  async addProjectContributorAsync({ commit }, { id, contributor }) {
    try {
      commit("setProjectLoading", true);
      await http.put(`/projects/${id}/contributors`, contributor);
      commit("addContributor", contributor);
      success(`${contributor.userId} added as project contributor.`);
    } catch (e) {
      console.log(e);
    } finally {
      commit("setProjectLoading", false);
    }
  },
  async removeProjectContributorAsync({ commit }, { id, userId }) {
    try {
      commit("setProjectLoading", true);
      await http.delete(`/projects/${id}/contributors/${userId}`);
      commit("removeContributor", userId);
    } catch (e) {
      console.log(e);
    } finally {
      commit("setProjectLoading", false);
    }
  },
};

const mutations = {
  setProjects: (state, projects) => (state.projects = projects),
  setProjectsLoading: (state, loading) => (state.projectsLoading = loading),
  setProject: (state, project) => (state.project = project),
  setProjectLoading: (state, loading) => (state.projectLoading = loading),
  setAssets: (state, assets) => (state.project.assets = assets),
  updateProjectAsset: (state, projectAssetUpdate) => {
    const index = state.project.assets.findIndex(
      (x) => x.id === projectAssetUpdate.id
    );

    state.project.assets[index].comments = projectAssetUpdate.comments;
    state.project.assets[index].segments = projectAssetUpdate.segments;
  },
  setProjectSongs: (state, projectSongs) =>
    (state.project.songs = projectSongs),
  updateClient: (state, client) => {
    state.project.client = client;
  },
  setProjectStatusSubmitted: (state) => (state.project.status = 2),
  addContributor: (state, contributor) =>
    state.project.contributors.push(contributor),
  removeContributor: (state, userId) => {
    const index = state.project.contributors.findIndex(
      (x) => x.userId === userId
    );

    state.project.contributors.splice(index, 1);
  },
  setProjectStatus: (state, status) => (state.project.status = status),
};

const getters = {
  // eslint-disable-next-line
  projectDuration: (state) => {
    let duration = {
      projectAssets: 0,
      songs: 0,
    };

    duration.projectAssets = state.project.assets.reduce((a, c) => {
      if (c.segments.length > 0) {
        a += c.segments.reduce((a2, c2) => (a2 += c2.end - c2.start), 0);
      } else {
        a += c.durationSeconds;
      }
      return a;
    }, 0);

    duration.songs = state.project.songs.reduce(
      (a, c) => (a += c.durationSeconds),
      0
    );

    return duration;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
