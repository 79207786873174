<template>
  <v-dialog
    persistent
    max-width="1000"
    :value="dialog"
    transition="dialog-bottom-transition">
    <v-card tile>
      <v-card-text class="pt-3 pb-0 mb-0">
        <v-row dense align="center">
          <v-col cols="4">
            <v-btn
              color="primary"
              block
              tile
              large
              @click="addAssets()"
              :disabled="projectLoading || selectedAssets.length === 0"
              >Add Selected To Project
            </v-btn>
          </v-col>
          <v-col cols="4" offset="4">
            <v-btn
              :disabled="projectLoading || assetsLoading || !assetKey"
              large
              block
              class="my-1"
              color="primary"
              tile
              @click="$refs.fileBrowser.click()"
              >Upload Assets</v-btn
            >
            <input
              type="file"
              accept="image/*,video/*"
              style="display: none"
              ref="fileBrowser"
              multiple
              @change="selectedFilesChanged" />
          </v-col>
          <v-col cols="12">
            Assets uploaded by project owner and contributors. Assets cannot be
            removed if they are added to project.
          </v-col>
        </v-row>
        <v-sheet style="position: relative">
          <v-data-table
            height="600"
            class="grey lighten-4 mt-3"
            :headers="headers"
            show-select
            v-model="selectedAssets"
            :items="assetsFormatted"
            hide-default-footer
            disable-filtering
            disable-pagination
            disable-sort
            item-key="absolutePath"
            :loading="assetsLoading"
            loading-text="Loading assets..."
            no-data-text="No assets found">
            <template v-slot:[`item.file`]="{ item }">
              <div class="subtitle-2">{{ item.file }}</div>
              <v-divider v-if="item.contributor"></v-divider>
              <div class="caption-2">{{ item.contributor }}</div>
            </template>
            <template v-slot:[`item.remove`]="{ item }">
              <div class="text-right">
                <v-btn text @click="viewAsset(item)" class="mr-1">View</v-btn>
                <v-btn @click="removeSelected(item)" text
                  ><span class="red--text">Remove</span></v-btn
                >
              </div>
            </template>
          </v-data-table>
          <v-overlay
            :opacity="0.7"
            :absolute="true"
            v-if="viewingAsset"
            class="text-center">
            <div>
              <v-img
                :src="viewingAsset.absolutePath + assetKey.key"
                max-height="500"
                max-width="700"
                :contain="true"></v-img>
            </div>
            <div>
              <v-btn text @click="viewingAsset = undefined">Close</v-btn>
            </div>
          </v-overlay>
          <LoadingOverlay
            :loading="assetsLoading || projectLoading"
            :absolute="true"
            :opacity="0.35"
            :progress="false" />
        </v-sheet>
      </v-card-text>
      <v-card-actions>
        <!-- <v-spacer></v-spacer> -->
        <v-btn
          color="primary"
          min-width="300"
          class="ml-2"
          outlined
          tile
          large
          :disabled="projectLoading"
          @click="close">
          <span>Close</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import { v4 as uuidv4 } from "uuid";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    id: {
      require: true,
    },
  },
  data() {
    return {
      selectedAssets: [],
      headers: [
        { text: "", value: "data-table-select" },
        { text: "File Name", value: "file" },
        { text: "", value: "remove" },
      ],
      viewingAsset: undefined,
    };
  },
  watch: {
    async dialog(newValue, oldValue) {
      if (newValue && !oldValue) {
        await this.listAssetsFlatAsync(this.id);
      }
    },
  },
  methods: {
    ...mapActions("assets", [
      "getAssetKeyAsync",
      "listAssetsFlatAsync",
      "uploadAssetsAsync",
      "removeAssetsAsync",
    ]),
    ...mapActions("projects", ["addProjectAssetsAsync"]),
    async addAssets() {
      let assetsToAdd = [];

      for (const asset of this.selectedAssets) {
        let contributor = null;

        if (asset.contributor) {
          contributor = {
            userId: asset.contributor,
          };
        }

        assetsToAdd.push({
          id: uuidv4(),
          absolutePath: asset.absolutePath,
          contentType: asset.contentType,
          durationSeconds: asset.durationSeconds,
          comments: "",
          contributor,
          segments: asset.segments,
        });
      }

      await this.addProjectAssetsAsync({
        id: this.id,
        assets: assetsToAdd,
      });
    },
    async selectedFilesChanged(event) {
      for (let file of event.target.files) {
        const asset = this.assetsFormatted.find((x) => x.file === file.name);
        if (asset && !asset.contributor) {
          alert(`Cannot upload ${file.name}. File already exists.`);
        }
      }

      const filesWithMetadata = [];

      for (const file of event.target.files) {
        let durationSeconds = await new Promise((resolve, reject) => {
          try {
            if (file.type.startsWith("image/")) {
              resolve(6);
            } else if (file.type.startsWith("video/")) {
              let video = document.createElement("video");
              video.preload = "metadata";

              video.onloadedmetadata = function () {
                resolve(this.duration);
              };

              video.onerror = function () {
                reject("Invalid video. Please select a video file.");
              };

              video.src = window.URL.createObjectURL(file);
            } else {
              reject(`File ${file.name} type ${file.type} is not supported.`);
            }
          } catch (error) {
            reject("Could not get asset duration.");
          }
        });

        let metadata = {
          durationSeconds,
          //contentType: file.type,
          segments: [],
        };

        filesWithMetadata.push({ file, metadata });
      }

      this.uploadAssetsAsync({
        id: this.id,
        filesWithMetadata,
        contributor: "",
      });

      this.$refs.fileBrowser.value = null;
    },
    async removeSelected(asset) {
      if (
        await this.$root.$confirm(
          "Remove selected assets?",
          "Selected assets will be permamently removed from project.",
          [],
          "red"
        )
      ) {
        await this.removeAssetsAsync({
          id: this.id,
          assets: [asset],
        });
      }
    },
    viewAsset(asset) {
      this.$root.$contentViewer(
        asset.absolutePath + this.assetKey.key,
        asset.contentType
      );
    },
    close() {
      this.$emit("close");
      this.selectedAssets = [];
    },
  },
  computed: {
    ...mapState("assets", [
      "assetKey",
      "assets",
      "assetKeyLoading",
      "assetsLoading",
    ]),
    ...mapState("projects", ["projectLoading"]),
    assetsFormatted() {
      return this.assets.map((x) => {
        const file = x.absolutePath.substring(
          x.absolutePath.lastIndexOf("/") + 1
        );
        return { file, ...x };
      });
    },
  },
  components: {
    LoadingOverlay,
  },
};
</script>
