<template>
  <v-overlay
    :class="[loading ? 'loading-skeleton' : '']"
    :absolute="absolute"
    :value="loading"
    :opacity="opacity"
    :color="color">
    <v-progress-circular
      v-show="progress"
      indeterminate
      size="240"
      width="12"
      color="primary">
      {{ text }}
    </v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  props: {
    absolute: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    opacity: {
      type: Number,
      default: 0.3,
    },
    text: {
      type: String,
      default: "Loading...",
    },
    progress: {
      type: Boolean,
      default: true,
    },
    color: {
      default: "white",
    },
  },
};
</script>

<style scoped>
.loading-skeleton {
  animation: skeleton-loading 0.75s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: rgba(255, 255, 255, 0.01);
  }
  100% {
    background-color: rgba(207, 216, 220, 0.4);
  }
}
</style>
