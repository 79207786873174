<template>
  <v-dialog v-model="enlarge" content-class="elevation-0" max-width="100%">
    <v-card tile dense elevation="0" color="transparent">
      <v-card-text class="ma-0 pa-0 text-center">
        <div v-if="contentType.startsWith('image/')">
          <v-img
            class="mx-auto"
            :lazy-src="require('@/assets/placeholder-image.jpg')"
            :src="src"
            contain
            max-height="700px"
            width="79%">
          </v-img>
        </div>
        <div v-if="contentType.startsWith('video/')">
          <video
            controls
            preload="metadata"
            style="width: 75%"
            :src="src"
            :poster="require('@/assets/video-poster.png')">
            Your browser does not support the video tag.
          </video>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn tile min-width="200" large class="mx-auto" @click="close"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    src: undefined,
    enlarge: false,
    contentType: "",
  }),
  methods: {
    open(src, contentType) {
      this.enlarge = true;
      this.src = src;
      this.contentType = contentType;
    },
    close() {
      this.enlarge = false;
      this.src = undefined;
      this.contentType = "";
    },
  },
};
</script>
