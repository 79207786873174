<template>
  <v-dialog :value="value" persistent max-width="700" v-if="value">
    <v-card tile min-height="500" class="grey lighten-3">
      <v-card-text class="pt-3 pb-0 mb-0" style="position: relative">
        <v-row dense no-gutters>
          <v-col cols="12" class="text-center">
            <v-img
              v-if="value.contentType.startsWith('image')"
              style="width: 100%; max-height: 400px"
              :contain="true"
              :src="value.absolutePath + assetKey.key"></v-img>
            <video
              v-if="value.contentType.startsWith('video')"
              style="width: 100%; max-height: 400px"
              controls
              :src="value.absolutePath + assetKey.key"
              :poster="require('@/assets/video-poster.png')"></video>
          </v-col>
          <v-col cols="12">
            <v-textarea
              class="mt-2"
              :hide-details="true"
              v-model="projectAsset.comments"
              auto-grow
              rows="3"
              filled
              row-height="15"
              label="Comments"></v-textarea>
            <div v-if="value.contentType.startsWith('video')">
              <v-simple-table class="grey lighten-2 mt-2">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th>Start</th>
                      <th>End</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-if="projectAsset.segments.length === 0">
                      <td colspan="3" class="px-1">
                        <v-alert
                          class="ma-0"
                          border="left"
                          dense
                          colored-border
                          type="info"
                          tile>
                          If segments are not defined, entire video is used by
                          the project.
                        </v-alert>
                      </td>
                    </tr>
                    <tr
                      v-for="(segment, i) in projectAsset.segments"
                      :key="i"
                      class="grey lighten-4 align-center">
                      <td width="40%" class="pl-1">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="segment.start"
                          v-mask="'##:##:##'"></v-text-field>
                      </td>
                      <td width="40%">
                        <v-text-field
                          outlined
                          dense
                          hide-details
                          v-model="segment.end"
                          v-mask="'##:##:##'"></v-text-field>
                      </td>
                      <td width="20%" class="text-left pr-1">
                        <v-btn
                          color="red"
                          outlined
                          tile
                          block
                          @click="removeSegment(i)"
                          >Remove</v-btn
                        >
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="3" class="px-1">
                        <v-btn min-width="150" outlined tile @click="addSegment"
                          >Add Segment</v-btn
                        >
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </div>
          </v-col>
        </v-row>
        <LoadingOverlay
          :loading="projectLoading"
          :absolute="true"
          :progress="false"></LoadingOverlay>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="ml-3"
          tile
          min-width="150"
          color="primary"
          :disabled="projectLoading"
          @click="updateAsset"
          >Save</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          class="mr-3"
          outlined
          tile
          min-width="150"
          @click="$emit('input', undefined)"
          >Close</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LoadingOverlay from "./LoadingOverlay.vue";
import { mapActions, mapState } from "vuex";
export default {
  props: {
    value: {
      require: true,
      default: () => undefined,
    },
    assetKey: {
      require: true,
    },
  },
  data() {
    return {
      projectAsset: {
        comments: "",
        segments: [],
      },
    };
  },
  watch: {
    value(newValue, oldValue) {
      if (oldValue === undefined && newValue) {
        let projectCloned = JSON.parse(JSON.stringify(this.value));

        for (let segment of projectCloned.segments) {
          segment.start = new Date(segment.start * 1000)
            .toISOString()
            .substring(11, 19);
          segment.end = new Date(segment.end * 1000)
            .toISOString()
            .substring(11, 19);
        }

        this.projectAsset = projectCloned;
      } else {
        this.projectAsset = {
          comments: "",
          segments: [],
        };
      }
    },
  },
  methods: {
    ...mapActions("projects", ["updateProjectAssetAsync"]),
    addSegment() {
      this.projectAsset.segments.push({ start: "00:00:00", end: "00:00:00" });
    },
    removeSegment(i) {
      this.projectAsset.segments.splice(i, 1);
    },
    async updateAsset() {
      const projectAssetUpdate = {
        comments: this.projectAsset.comments,
        segments: this.projectAsset.segments.map((x) => ({
          start: x.start.split(":").reduce((acc, time) => 60 * acc + +time),
          end: x.end.split(":").reduce((acc, time) => 60 * acc + +time),
        })),
      };
      await this.updateProjectAssetAsync({
        id: this.project.id,
        projectAsset: this.value,
        projectAssetUpdate,
      });
    },
  },
  computed: {
    ...mapState("projects", ["project", "projectLoading"]),
    canUpdate() {
      if (this.value) {
        const contentType = this.value.contentType;

        return (
          contentType.startsWith("image") || contentType.startsWith("video")
        );
      } else {
        return false;
      }
    },
  },
  components: {
    LoadingOverlay,
  },
};
</script>
