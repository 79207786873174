<template>
  <v-sheet>
    <v-row dense no-gutters>
      <v-col cols="12" sm="12" md="4" lg="2">
        <v-btn
          large
          block
          tile
          color="primary"
          @click="updateDetails"
          :disabled="projectLoading || !valid">
          Update Client Information</v-btn
        >
      </v-col>
    </v-row>
    <v-row class="mt-2" dense>
      <v-col cols="12" md="12" lg="6">
        <div class="text-h6 primary--text">Client information</div>
        <v-divider class="mb-4"></v-divider>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            dense
            label="First name"
            :loading="projectLoading"
            v-model="client.firstName"
            :rules="rules.buyerFirstName"
            outlined></v-text-field>
          <v-text-field
            dense
            label="Middle name"
            outlined
            v-model="client.middleName"
            :loading="projectLoading"></v-text-field>
          <v-text-field
            dense
            label="Last name"
            v-model="client.lastName"
            :loading="projectLoading"
            :rules="rules.buyerLastName"
            outlined></v-text-field>
          <v-text-field
            dense
            label="Phone number"
            v-model="client.phoneNumber"
            :loading="projectLoading"
            outlined></v-text-field>
          <v-text-field
            dense
            label="Email address"
            v-model="client.email"
            :loading="projectLoading"
            :rules="rules.buyerEmailAddress"
            outlined></v-text-field>
          <v-textarea
            filled
            disabled
            outlined
            rows="3"
            label="Comments"
            auto-grow></v-textarea>
        </v-form>
      </v-col>
      <v-col cols="12" md="6">
        <div class="text-h6 primary--text">Deceased person information</div>
        <v-divider class="mb-4"></v-divider>
        <div class="grey lighten-2 mb-1">
          <div class="mx-3 py-1">
            <div class="caption">First name</div>
            <div>
              {{ project.deceasedPerson.firstName }}
            </div>
          </div>
          <v-divider></v-divider>
        </div>

        <div class="grey lighten-2 mb-1">
          <div class="mx-3 py-1">
            <div class="caption">Middle name</div>
            <div>
              {{ project.deceasedPerson.middleName }}
            </div>
          </div>
          <v-divider></v-divider>
        </div>

        <div class="grey lighten-2 mb-1">
          <div class="mx-3 py-1">
            <div class="caption">Last name</div>
            <div>
              {{ project.deceasedPerson.lastName }}
            </div>
          </div>
          <v-divider></v-divider>
        </div>

        <div class="grey lighten-2 mb-1">
          <div class="mx-3 py-1">
            <div class="caption">Birth date</div>
            <div>
              {{ project.deceasedPerson.birthDate | formatDate }}
            </div>
          </div>
          <v-divider></v-divider>
        </div>

        <div class="grey lighten-2 mb-2">
          <div class="mx-3 py-1">
            <div class="caption">Death date</div>
            <div>
              {{ project.deceasedPerson.deathDate | formatDate }}
            </div>
          </div>
          <v-divider></v-divider>
        </div>
      </v-col>
      <!-- <v-col cols="12" md="6" v-if="project.contributors">
        <v-form
          ref="contributorForm"
          v-model="contributorFormValid"
          lazy-validation>
          <div class="text-h6 primary--text">Project contributors</div>
          <v-alert border="left" dense colored-border type="info" elevation="0">
            Invited project contributors can upload assets to project.
          </v-alert>
          <v-row class="mb-2 px-1" dense>
            <v-col
              cols="12"
              v-for="contributor in project.contributors"
              :key="contributor.userId">
              <v-row dense class="grey lighten-4" align="center">
                <v-col cols="2">
                  <v-btn
                    color="red"
                    class="white--text"
                    @click="removeContributor(contributor)"
                    :disabled="projectLoading"
                    block
                    tile
                    >Remove</v-btn
                  >
                </v-col>
                <v-col cols="10">
                  {{ contributor.userId }}
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2">
              <v-btn
                color="primary"
                @click="inviteContributor"
                :disabled="projectLoading"
                block
                tile
                >Invite</v-btn
              >
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model="contributorEmail"
                label="Contributor email"
                :rules="rules.buyerEmailAddress"
                outlined
                dense>
              </v-text-field>
            </v-col> </v-row
        ></v-form>
      </v-col> -->
    </v-row>
  </v-sheet>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      valid: false,
      contributorFormValid: false,
      rules: {
        buyerFirstName: [(v) => !!v || "First name is required"],
        buyerMiddleName: {},
        buyerLastName: [(v) => !!v || "Last name is required"],
        buyerPhoneNumber: {},
        buyerEmailAddress: [
          (v) => !!v || "Email is required",
          (v) =>
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || "Email must be valid",
        ],
      },
      contributorEmail: undefined,
      client: {
        firstName: undefined,
        middleName: undefined,
        lastName: undefined,
        phoneNumber: undefined,
        email: undefined,
      },
    };
  },
  created() {
    this.client = { ...this.project.client };
  },
  methods: {
    ...mapActions("projects", [
      "updateClientAsync",
      "addProjectContributorAsync",
      "removeProjectContributorAsync",
    ]),
    async inviteContributor() {
      if (this.$refs.contributorForm.validate()) {
        await this.addProjectContributorAsync({
          id: this.id,
          contributor: {
            userId: this.contributorEmail,
          },
        });
        this.contributorEmail = undefined;
        this.$refs.contributorForm.reset();
      }
    },
    async removeContributor({ userId }) {
      await this.removeProjectContributorAsync({
        id: this.id,
        userId,
      });
    },
    async updateDetails() {
      if (this.$refs.form.validate()) {
        const update = {
          id: this.id,
          client: this.client,
        };
        await this.updateClientAsync(update);
      }
    },
  },
  computed: {
    ...mapState("projects", ["project", "projectLoading"]),
  },
};
</script>
