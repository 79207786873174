import http from "../../services/http";
import { error } from "@/helpers/notificationsHandler";

const initialState = {
  videoKey: {},
  videoKeyLoading: false,
  projectVideos: [],
  projectVideosLoading: false,
};

const state = { ...initialState };

const actions = {
  async getProjectVideosAsync({ commit }, id) {
    try {
      commit("setProjectVideosLoading", true);
      const { data } = await http.get(`/projects/${id}/videos`);
      commit("setProjectVideos", data);
    } catch (error) {
      console.log(error);
    } finally {
      commit("setProjectVideosLoading", false);
    }
  },
  async getVideoKeyAsync({ commit }, id) {
    try {
      commit("setVideoKeyLoading", true);
      const { data } = await http.get(`/projects/${id}/videos/key`);
      commit("setVideoKey", data);
    } catch {
      error("Could not obtain project video access key.");
    } finally {
      commit("setVideoKeyLoading", false);
    }
  },
  async approveProjectVideoAsync({ commit }, { id, projectVideoId, feedback }) {
    try {
      commit("projects/setProjectLoading", true, { root: true });
      await http.post(`/projects/${id}/videos/${projectVideoId}`, feedback);
      commit(
        "projects/setProjectStatus",
        feedback.approved ? "Approved" : "Rejected",
        { root: true }
      );
    } catch {
      error("Could not obtain project video access key.");
    } finally {
      commit("projects/setProjectLoading", false, { root: true });
    }
  },
};

const mutations = {
  setProjectVideos: (state, projectVideos) =>
    (state.projectVideos = projectVideos),
  setProjectVideosLoading: (state, loading) =>
    (state.projectVideosLoading = loading),
  setVideoKey: (state, videoKey) => (state.videoKey = videoKey),
  setVideoKeyLoading: (state, loading) => (state.videoKeyLoading = loading),
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
