export const msalConfig = {
  auth: {
    clientId: "40503424-39cf-4260-b1f7-d6b8da8e942a",
    authority:
      "https://momorocb2c.b2clogin.com/momorocb2c.onmicrosoft.com/B2C_1_SignInSignUp",
    knownAuthorities: ["momorocb2c.b2clogin.com"],
    redirectUri: process.env.VUE_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.VUE_APP_REDIRECT_URI,
    scopes: [
      "https://momorocb2c.onmicrosoft.com/40a88bc7-6655-46b8-bbb8-63211e2066d4/User.ReadWrite",
    ],
  },
};

export const loginRequest = {
  scopes: [
    "https://momorocb2c.onmicrosoft.com/40a88bc7-6655-46b8-bbb8-63211e2066d4/User.ReadWrite",
  ],
};