<template>
  <v-dialog v-model="dialog" max-width="500" @keydown.esc="cancel">
    <v-card>
      <v-card-title :class="[color, 'white--text']" primary-title>
        {{ title }}
      </v-card-title>
      <v-card-text class="body-2 black--text mb-1 pb-0">
        <div class="mt-3">{{ message }}</div>
        <v-list dense v-if="checkboxes.length > 0" class="mx-0 px-0">
          <v-list-item
            class="mx-0 px-0"
            v-for="(checkbox, i) in checkboxes"
            :key="i">
            <v-list-item-action>
              <v-checkbox v-model="checkbox.value"></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-content>
                {{ checkbox.text }}
              </v-list-item-content>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions class="py-1 grey lighten-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" :disabled="!yesEnabled" @click.native="agree"
          >Yes</v-btn
        >
        <v-btn color="red" text @click.native="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    checkboxes: [],
    title: null,
    color: "",
  }),
  methods: {
    open(title, message, checkboxes, color) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.checkboxes = checkboxes.map((x) => ({ text: x, value: false }));
      this.color = color;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
  computed: {
    yesEnabled() {
      if (this.checkboxes.length === 0) return true;

      return this.checkboxes.every((x) => x.value);
    },
  },
};
</script>
