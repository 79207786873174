<template>
  <v-row justify="center" dense class="mt-4">
    <v-col cols="12" lg="10">
      <v-card elevation="2" tile>
        <v-img
          height="350"
          :src="require('@/assets/bg.jpg')"
          gradient="to bottom, rgba(0,0,0,.15), rgba(0,0,0,.425)"
          class="align-center"
          ><div class="white--text align-center text-center text-h4">
            {{ welcomeMessage }}
          </div></v-img
        >
        <v-card-title primary-title>My Projects </v-card-title>
        <v-card-subtitle class="body-2"
          >Need help?
          <router-link to="/help"
            ><span class="text--blue font-weight-bold"
              >Watch our guide here!</span
            ></router-link
          >
        </v-card-subtitle>
        <v-card-text>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Deceased Person</th>
                  <th class="text-left">Project Status</th>
                  <th class="text-left">Project Role</th>
                  <th class="text-left">Last Modified</th>
                </tr>
              </thead>
              <tbody v-if="projectsFormatted.length > 0">
                <tr
                  style="cursor: pointer"
                  v-for="project in projectsFormatted"
                  :key="project.id"
                  @click="navigateToProject(project)"
                  class="grey lighten-4">
                  <td>
                    {{ project.fullName }}
                    <span v-if="project.deceasedPerson.deathDate">
                      <span>,</span>
                      {{ project.deceasedPerson.birthDate | formatDate }} -
                      {{ project.deceasedPerson.deathDate | formatDate }}</span
                    >
                  </td>
                  <td>{{ project.status }}</td>
                  <td>{{ project.owner ? "Owner" : "Contributor" }}</td>
                  <td>{{ project.lastUpdatedUtc | formatDateShort }}</td>
                </tr>
              </tbody>
              <tbody
                v-else-if="projectsFormatted.length == 0 && projectsLoading">
                <tr class="text-center">
                  <td colspan="4">Loading projects...</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="text-center">
                  <td colspan="4">No projects</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <LoadingOverlay
            :loading="projectsLoading"
            :opacity="0.25"
            :absolute="true"
            :progress="false" />
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import { mapActions, mapState } from "vuex";
export default {
  async created() {
    await this.getProjectsAsync();
  },
  methods: {
    ...mapActions("projects", ["getProjectsAsync"]),
    navigateToProject(project) {
      this.$router.push(`projects/${project.id}`);
    },
  },
  computed: {
    ...mapState("projects", ["projects", "projectsLoading"]),
    projectsFormatted() {
      return this.projects.map((x) => {
        const fullName = `${x.deceasedPerson.firstName} ${
          x.deceasedPerson.middleName != null ? x.deceasedPerson.middleName : ""
        } ${x.deceasedPerson.lastName}`;

        return { fullName, ...x };
      });
    },
    welcomeMessage() {
      try {
        if (!localStorage.getItem("welcome")) {
          localStorage.setItem("welcome", "welcome");
          return "Welcome to Memo-Roc!";
        } else {
          return "Welcome back to Memo-Roc!";
        }
      } catch {
        return "Welcome to Memo-Roc!";
      }
    },
  },
  components: {
    LoadingOverlay,
  },
};
</script>
