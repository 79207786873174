<template>
  <v-card min-height="500">
    <v-row dense no-gutters class="mx-0">
      <v-col cols="12" v-if="project.id && !initializing">
        <div v-if="project.owner" :initialized="initialized">
          <div v-if="project.status === 'Active'">
            <ProjectNavigation />
            <router-view class="ma-2" />
          </div>
          <div v-else-if="project.status === 'Submitted'">
            <SumittedState />
          </div>
          <div v-else-if="project.status === 'PendingApproval'">
            <PendingApprovalState />
          </div>
          <div
            v-else-if="
              project.status === 'Approved' || project.status === 'Rejected'
            ">
            <ApprovedRejectedState />
          </div>
          <div v-else>Unknown project status.</div>
        </div>
        <div v-else>
          <ProjectAssetsContributorView :id="project.id" />
        </div>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
        v-else-if="!project.id && !initializing">
        <div style="margin-top: 225px" class="text-h5">Error</div>
        <div class="caption-2">Unexpected error while loading project.</div>
      </v-col>
      <LoadingOverlay
        :loading="initializing === true && projectLoading === true"
        :absolute="true"
        :text="'Loading project...'"></LoadingOverlay>
    </v-row>
    <UploadProgress />
  </v-card>
</template>

<script>
import PendingApprovalState from "@/components/projectStates/PendingApprovalState.vue";
import ApprovedRejectedState from "@/components/projectStates/ApprovedRejectedState.vue";
import SumittedState from "@/components/projectStates/SubmittedState.vue";
import ProjectAssetsContributorView from "./ProjectAssetsContributorView.vue";
import UploadProgress from "@/components/UploadProgress.vue";

import LoadingOverlay from "@/components/LoadingOverlay.vue";
import ProjectNavigation from "@/components/ProjectNavigation.vue";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      pages: ["assets", "songs", "details", "submit"],
      initializing: true,
      initializationError: null,
    };
  },
  async created() {
    this.initializing = true;
    try {
      if (await this.getProjectAsync(this.id)) {
        this.$router.push({ path: `/not-found/${this.id}` });
      }
      // else if (this.project.status !== "Active") {
      //   this.$router.push({ path: `/projects/${this.id}/submitted` });
      // }
    } catch {
      this.$router.push({ path: `/not-found/${this.id}` });
    } finally {
      this.initializing = false;
    }
  },
  methods: {
    ...mapActions("projects", ["getProjectAsync"]),
    navigate(path) {
      this.$router.push({ path }).catch(() => {});
    },
  },
  computed: {
    ...mapState("projects", ["project", "projectLoading"]),
    ...mapGetters("projects", ["projectDuration"]),
    step() {
      const route = this.$route.path.split("/").pop();
      return this.pages.indexOf(route) + 1;
    },
    initialized() {
      return !this.project.id && !this.projectLoading;
    },
  },
  components: {
    PendingApprovalState,
    ApprovedRejectedState,
    SumittedState,
    UploadProgress,
    ProjectAssetsContributorView,
    ProjectNavigation,
    LoadingOverlay,
  },
};
</script>
