<template>
  <div>
    <v-row dense align="center">
      <v-col cols="12" lg="2">
        <v-btn
          tile
          block
          color="primary"
          :loading="assetKeyLoading"
          :disabled="assetKeyLoading || projectLoading"
          @click="
            () => {
              showAssetSelectionAndUpload = true;
            }
          "
          >Add Project Assets</v-btn
        >
      </v-col>
      <v-col cols="12" lg="2">
        <v-btn
          block
          class="white--text my-1"
          color="red"
          tile
          min-width="300"
          @click="removeProjectAssets"
          :disabled="projectLoading"
          >Remove Selected</v-btn
        >
      </v-col>
      <v-col cols="12">
        <v-alert border="left" dense colored-border type="info" elevation="0">
          To rearrange project assets click and hold on row, and move it up or
          down. Click
          <v-icon style="font-size: 28px" color="primary"
            >mdi-pencil-circle-outline</v-icon
          >
          to edit project asset.
        </v-alert>
      </v-col>
    </v-row>
    <v-sheet min-height="500" style="position: relative">
      <v-overlay
        :absolute="true"
        color="white"
        class="black--text text-center"
        :value="project.assets.length === 0 && !projectLoading">
        <div class="text-h5">Project contains no assets</div>
        <div class="caption-2">
          Click on "Add Project Assets" to add new project assets to project.
        </div>
      </v-overlay>
      <draggable
        class="row no-gutters dense"
        :force-fallback="true"
        v-model="projectAssetsFormatted">
        <v-col
          cols="12"
          v-for="projectAsset in projectAssetsFormatted"
          :key="projectAsset.id">
          <v-row dense no-gutters align="center" class="grey lighten-4 mb-2">
            <v-col cols="12" sm="3" md="2">
              <v-row dense>
                <v-col cols="2">
                  <v-row
                    justify="center"
                    align="center"
                    no-gutters
                    dense
                    class="text-center fill-height">
                    <v-col cols="12">
                      <input
                        :id="`id_${projectAsset.id}`"
                        type="checkbox"
                        style="transform: scale(1.5)"
                        :value="projectAsset"
                        :true-value="[]"
                        v-model="selectedProjectAssets" />
                    </v-col>
                    <v-col cols="12">
                      <v-btn icon @click="selectedProjectAsset = projectAsset">
                        <v-icon style="font-size: 28px" color="primary"
                          >mdi-pencil-circle-outline</v-icon
                        >
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="10">
                  <div class="mx-2 py-1">
                    <!-- <v-img
                      style="border-radius: 5px"
                      v-if="projectAsset.contentType.startsWith('image/')"
                      class="mx-auto"
                      :lazy-src="require('@/assets/placeholder-image.jpg')"
                      :max-height="90"
                      :width="'100%'"
                      :src="projectAsset.absolutePath + assetKey.key"
                      :aspect-ratio="1.7778"></v-img> -->
                    <ContentWrapper
                      :contentType="projectAsset.contentType"
                      :contentSrc="projectAsset.absolutePath + assetKey.key" />
                    <!-- <div v-else style="width: 100%" class="text-center">
                      <v-icon size="80" color="red"> mdi-youtube </v-icon>
                    </div> -->
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="9" md="10">
              <v-row dense>
                <v-col cols="10" md="9" lg="10">
                  <div class="ml-4">
                    <div class="subtitle-2">{{ projectAsset.file }}</div>
                    <div v-if="projectAsset.contributor">
                      <v-divider></v-divider>
                      <div class="caption-2">
                        {{ projectAsset.contributor.userId }}
                      </div>
                    </div>
                    <div v-if="projectAsset.comments">
                      <v-divider></v-divider>
                      <div class="caption-2">
                        {{ projectAsset.comments }}
                      </div>
                    </div>
                  </div>
                </v-col>
                <v-col cols="2" md="3" lg="2">
                  <div class="text-center">
                    {{ projectAsset.durationFormatted.start | secondsToHHMMSS }}
                    -
                    {{ projectAsset.durationFormatted.end | secondsToHHMMSS }}
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <LoadingOverlay
          :loading="projectLoading"
          :absolute="true"
          :opacity="0.35"
          :progress="false" />
      </draggable>
    </v-sheet>
    <AssetsUploadAndSelection
      @close="showAssetSelectionAndUpload = false"
      :id="id"
      :dialog="showAssetSelectionAndUpload" />
    <EditProjectAsset v-model="selectedProjectAsset" :assetKey="assetKey" />
    <!-- <EditProjectAsset
      @close="selectedProjectAsset = undefined"
      :projectAsset="selectedProjectAsset" /> -->
  </div>
</template>

<script>
/* eslint-disable */
import AssetsUploadAndSelection from "@/components/AssetsUploadAndSelection.vue";
import EditProjectAsset from "@/components/EditProjectAsset.vue";
import LoadingOverlay from "@/components/LoadingOverlay.vue";
import ContentWrapper from "@/components/ContentWrapper.vue";
import { mapActions, mapState } from "vuex";
import draggable from "vuedraggable";
export default {
  props: ["id"],
  data() {
    return {
      selectedProjectAsset: undefined,
      selectedProjectAssets: [],
      showAssetSelectionAndUpload: false,
    };
  },
  async created() {
    await this.getAssetKeyAsync(this.id);
  },
  methods: {
    ...mapActions("assets", ["getAssetKeyAsync"]),
    ...mapActions("projects", [
      "addProjectAssetsAsync",
      "changeProjectAssetOrderAsync",
      "removeProjectAssetsAsync",
    ]),
    async removeProjectAssets() {
      if (
        await this.$root.$confirm(
          "Remove project assets?",
          "Are you sure you want to remove selected project assets?",
          [],
          "red"
        )
      ) {
        await this.removeProjectAssetsAsync({
          id: this.id,
          assets: this.selectedProjectAssets,
        });
        this.selectedProjectAssets = [];
      }
    },
  },
  computed: {
    ...mapState("assets", ["assetKey", "assetKeyLoading"]),
    ...mapState("projects", ["project", "projectLoading"]),
    projectAssetsFormatted: {
      get() {
        let projectAssetsFormatted = this.project.assets.map((x) => {
          const file = x.absolutePath.substring(
            x.absolutePath.lastIndexOf("/") + 1
          );

          let durationFormatted = { start: 0, end: 0, durationSeconds: 0 };

          if (x.contentType.startsWith("image")) {
            durationFormatted.durationSeconds = x.durationSeconds;
          } else if (x.contentType.startsWith("video")) {
            if (x.segments.length > 0) {
              for (const segment of x.segments) {
                durationFormatted.durationSeconds +=
                  segment.end - segment.start;
              }
            } else {
              durationFormatted.durationSeconds = x.durationSeconds;
            }
          } else {
            durationFormatted.durationSeconds = "durationError";
          }

          return { file, durationFormatted, ...x };
        });

        return projectAssetsFormatted.map((x, i) => {
          if (i === 0) {
            x.durationFormatted.end = x.durationSeconds;
          } else {
            x.durationFormatted.start =
              projectAssetsFormatted[i - 1].durationFormatted.end;
            x.durationFormatted.end =
              x.durationFormatted.start + x.durationFormatted.durationSeconds;
          }

          return x;
        });
      },
      async set(value) {
        await this.changeProjectAssetOrderAsync({
          id: this.id,
          assets: value,
        });
      },
    },
  },
  components: {
    draggable,
    LoadingOverlay,
    ContentWrapper,
    EditProjectAsset,
    AssetsUploadAndSelection,
  },
};
</script>
