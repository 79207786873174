<template>
  <v-sheet min-height="90vh">
    <v-carousel
      v-model="model"
      hide-delimiters
      :show-arrows="false"
      class="black"
      height="90vh">
      <v-carousel-item
        v-for="(projectVideo, i) in projectVideosFormatted"
        :key="i">
        <v-row
          dense
          no-gutters
          class="fill-height"
          align="center"
          justify="center">
          <v-col cols="12" class="text-center">
            <video
              class=""
              controls
              :src="projectVideo.absolutePath + videoKey.key"
              :poster="require('@/assets/video-poster.png')"
              preload="metadata">
              Your browser does not support the video tag.
            </video>
            <div>
              <v-btn
                color="white"
                x-large
                text
                @click="model--"
                v-if="model !== 0">
                <span
                  >Version #{{
                    projectVideosFormatted[model - 1].version
                  }}</span
                >
                <v-icon x-large>mdi-arrow-left-thin-circle-outline </v-icon>
              </v-btn>
              <v-btn
                x-large
                tile
                color="primary"
                min-width="200"
                class="mx-2"
                :disabled="projectLoading"
                @click="approve(true)"
                ><span class="white--text">Approve</span></v-btn
              >
              <v-btn
                x-large
                tile
                color="red"
                min-width="200"
                class="mx-2"
                :disabled="projectLoading"
                @click="approve(false)"
                ><span class="white--text">Reject</span></v-btn
              >
              <v-btn
                color="white"
                x-large
                text
                @click="model++"
                :disabled="projectLoading"
                v-if="model + 1 < projectVideosFormatted.length">
                <span
                  >Version #{{
                    projectVideosFormatted[model + 1].version
                  }}</span
                >
                <v-icon x-large>mdi-arrow-right-thin-circle-outline</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-carousel-item>
    </v-carousel>
    <v-dialog v-model="approveDialog" max-width="600" persistent>
      <v-card>
        <v-card-title class="px-4">
          <div v-if="approveVideo">Approve selected project video?</div>
          <div v-else>Reject project video?</div>
        </v-card-title>
        <v-card-text class="px-4">
          <div
            v-if="project.configuration.allowedRevisions > 0 && !approveVideo"
            class="black--text">
            <div class="mb-1">
              <v-icon class="mr-1">mdi-circle-small </v-icon> You are eligable
              to reject and request project changes up to
              <span class="font-weight-bold">{{
                project.configuration.allowedRevisions
              }}</span>
              time(s).
            </div>
            <div class="mb-1">
              <v-icon class="mr-1">mdi-circle-small </v-icon> You have rejected
              project
              <span class="font-weight-bold"
                >{{ projectVideosRejected }}/{{
                  project.configuration.allowedRevisions
                }}</span
              >
              times.
            </div>
            <div>
              <v-icon class="mr-1">mdi-circle-small </v-icon> If you reject
              project video
              <span class="font-weight-bold">{{
                project.configuration.allowedRevisions
              }}</span>
              time(s), last version will be treated as final version.
            </div>
          </div>
          <v-list dense class="mx-0 px-0">
            <v-list-item v-if="approveVideo" class="mx-0 px-0">
              <v-list-item-action>
                <v-checkbox v-model="approveVideoCheckbox"></v-checkbox>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-content>
                  I acknowledge that by approving selected project video I am
                  not eligable for any further changes to the video.
                </v-list-item-content>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-textarea
            v-if="!approveVideo"
            v-model="feedback.comments"
            rows="5"
            label="Describe required changes"
            outlined
            dense>
          </v-textarea>
        </v-card-text>
        <v-card-actions class="py-1 grey lighten-4">
          <v-btn
            color="primary"
            :disabled="
              (approveVideo && !approveVideoCheckbox) || projectLoading
            "
            @click="approveSelectedVideo"
            min-width="150"
            >Yes</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            tile
            min-width="150"
            :disabled="projectLoading"
            @click="approveDialog = false"
            ><span class="white--text">Cancel</span></v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <LoadingOverlayVue
      :loading="initializing"
      :absolute="true"
      :opacity="0.1"
      :color="'black'"
      :progress="false"></LoadingOverlayVue>
    <v-overlay
      v-if="initializaionError"
      :absolute="true"
      :opacity="0.1"
      color="black">
      <div class="text--white text-h5">Failed to load project videos</div>
    </v-overlay>
  </v-sheet>
</template>

<script>
import { mapActions, mapState } from "vuex";
import LoadingOverlayVue from "../LoadingOverlay.vue";

export default {
  data() {
    return {
      initializing: false,
      initializaionError: undefined,
      model: 0,
      approveDialog: false,
      approveVideo: undefined,
      approveVideoCheckbox: false,
      projectVideo: undefined,
      feedback: {
        comments: undefined,
      },
    };
  },
  async created() {
    try {
      this.initializing = true;
      await this.getProjectVideosAsync(this.project.id);
      await this.getVideoKeyAsync(this.project.id);
    } catch {
      this.initializaionError = true;
    }
    this.initializing = false;
  },
  methods: {
    ...mapActions("projectVideos", [
      "getProjectVideosAsync",
      "getVideoKeyAsync",
      "approveProjectVideoAsync",
    ]),
    approve(approve) {
      this.projectVideo = { ...this.projectVideosFormatted[this.model] };
      this.approveVideo = approve;
      this.approveDialog = true;
    },
    async approveSelectedVideo() {
      const command = {
        id: this.project.id,
        projectVideoId: this.projectVideosFormatted[this.model].id,
        feedback: {
          approved: this.approveVideo,
          comments: !this.approveVideo ? this.feedback.comments : null,
        },
      };

      await this.approveProjectVideoAsync(command);
    },
  },
  computed: {
    ...mapState("projects", ["project", "projectLoading"]),
    ...mapState("projectVideos", ["projectVideos", "videoKey"]),
    projectVideosFormatted() {
      return [...this.projectVideos].sort((a, b) => b.version - a.version);
    },
    projectVideosRejected() {
      return this.projectVideos.filter(
        (x) => x.feedback && !x.feedback.approved
      ).length;
    },
  },
  components: {
    LoadingOverlayVue,
  },
};
</script>

<style scoped>
video {
  width: 77%;
  height: 77%;
  object-fit: fill;
}
</style>
