import http from "../../services/http";

const initialState = {
  songCategories: [],
  songCategoriesLoading: false,
};

const state = { ...initialState };

const actions = {
  async getSongCategoriesAsync({ commit }) {
    try {
      commit("setSongCategoriesLoading", true);
      const { data } = await http.get("/songCategories");
      commit("setSongCategories", data);
    } catch (error) {
      console.log(error);
    } finally {
      commit("setSongCategoriesLoading", false);
    }
  },
};

const mutations = {
  setSongCategories: (state, songCategories) =>
    (state.songCategories = songCategories),
  setSongCategoriesLoading: (state, loading) =>
    (state.songCategoriesLoading = loading),
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
