import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import projects from "./modules/projects";
import assets from "./modules/projectAssets";
import notifications from "./modules/notifications";
import songCategories from "./modules/songCategories";
import songs from "./modules/songs";
import projectVideos from "./modules/projectVideos";

export default new Vuex.Store({
  modules: {
    songCategories,
    projectVideos,
    notifications,
    projects,
    assets,
    songs,
  },
});
