import NotFoundVue from "@/views/projects/NotFound.vue";
import ProjectAssetsViewVue from "@/views/projects/ProjectAssetsView.vue";
import ProjectSongsViewVue from "@/views/projects/ProjectSongsView.vue";
import ProjectDetailsViewVue from "@/views/projects/ProjectDetailsView.vue";
import ProjectReviewViewVue from "@/views/projects/ProjectReviewView.vue";
import ProjectRootViewVue from "@/views/projects/ProjectRootView.vue";
import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import HelpView from "@/views/HelpView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/projects/:id",
    props: true,
    component: ProjectRootViewVue,
    children: [
      // {
      //   path: "",
      //   redirect: "assets",
      //   props: true,
      // },
      {
        path: "",
        component: ProjectAssetsViewVue,
        props: true,
      },
      {
        path: "songs",
        component: ProjectSongsViewVue,
        props: true,
      },
      {
        path: "details",
        component: ProjectDetailsViewVue,
        props: true,
      },
      {
        path: "review",
        component: ProjectReviewViewVue,
        props: true,
      },
    ],
  },
  {
    path: "/not-found/:id",
    name: "not-found",
    component: NotFoundVue,
    props: true,
  },
  {
    path: "/help",
    component: HelpView,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
