<template>
  <v-snackbar
    v-show="assetsUploading.length > 0"
    style="padding: none"
    :timeout="-1"
    :value="true"
    center
    tile
    elevation="0"
    min-width="450">
    <v-row dense no-gutters>
      <v-col cols="12">
        <div class="d-flex align-center">
          <span class="white--text pl-2" v-if="uploading.length > 0">
            Uploading {{ uploading.length }} files...</span
          >
          <span v-else class="pl-2">Files Uploaded</span>
          <v-spacer></v-spacer>
          <v-btn
            text
            small
            color="white"
            tile
            @click="showUploadDetails = !showUploadDetails">
            <span v-if="!showUploadDetails"
              ><v-icon style="padding-bottom: 3px">mdi-chevron-up</v-icon> Show
              Details</span
            >
            <span v-else
              ><v-icon style="padding-bottom: 3px">mdi-chevron-down</v-icon>
              Hide Details</span
            >
          </v-btn>
          <v-btn
            v-if="uploading.length == 0"
            text
            small
            color="white"
            tile
            @click="clearAssetsUploading"
            >Close</v-btn
          >
        </div>
        <v-progress-linear
          :active="uploading.length > 0"
          class="xl-2"
          color="white"
          indeterminate
          rounded
          height="4"></v-progress-linear>
      </v-col>
      <v-col cols="12" v-if="showUploadDetails">
        <v-simple-table light style="max-height: 300px; overflow-y: scroll">
          <template v-slot:default>
            <tbody>
              <tr v-for="(asset, i) in assetsUploadingFormatted" :key="i">
                <td width="20" class="text-center">
                  <v-btn
                    @click="cancelUpload(asset)"
                    v-if="!isNaN(asset.progress) && asset.progress < 1"
                    outlined
                    color="red"
                    small
                    tile>
                    <span class="red--text">Cancel</span>
                  </v-btn>
                  <span v-else-if="asset.progress === 1">Uploaded</span>
                  <span v-else>Canceled</span>
                </td>
                <td class="text-left">{{ asset.name }}</td>
                <td class="text-right">
                  <span v-if="!isNaN(asset.progress)"
                    >{{ asset.progress * 100 }}%</span
                  >
                  <span v-else>{{ asset.progress }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      showUploadDetails: false,
      uploading2: true,
    };
  },
  methods: {
    ...mapActions("assets", [
      "cancelAssetUpload",
      "cancelAssetUploadAsync",
      "clearAssetsUploading",
    ]),
    viewUploadDetails() {},
    async cancelUpload({ absolutePath, name }) {
      await this.cancelAssetUploadAsync({ absolutePath, fileName: name });
      //this.cancelAssetUpload(item.name);
    },
  },
  computed: {
    ...mapState("assets", ["assetsUploading"]),
    uploading() {
      return this.assetsUploading.filter(
        (x) => !isNaN(x.progress) && x.progress !== 1
      );
    },
    assetsUploadingFormatted() {
      return this.assetsUploading.map((x) => {
        const name = x.absolutePath.substring(
          x.absolutePath.lastIndexOf("/") + 1
        );
        return { ...x, name };
      });
    },
  },
};
</script>

<style scoped>
.v-snack >>> .v-snack__content {
  padding: 0px 0px 0px 0px !important;
}

.v-snack >>> .v-snack__action {
  margin: 0px 0px 0px 0px !important;
}

.v-data-table {
  border-radius: 0px !important;
}
</style>
