import { exception } from "@/helpers/notificationsHandler";
import { msalInstance } from "vue-msal-browser";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import axios from "axios";

const HttpClient = axios.create({
  //baseURL: "https://localhost:7089/api",
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

const acquireToken = async () => {
  const request = {
    account: msalInstance.getAccountByHomeId(
      msalInstance.getAllAccounts()[0].homeAccountId
    ),
    scopes: msalInstance.extendedConfiguration.auth.scopes,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(request);

    if (!response.accessToken || response.accessToken === "") {
      throw new msalInstance.InteractionRequiredAuthError();
    } else {
      return response.accessToken;
    }
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      await msalInstance.acquireTokenRedirect(request);
    } else {
      console.error(error);
    }
  }
};

HttpClient.interceptors.request.use(
  async (options) => {
    let accessToken = await acquireToken();

    options.headers.Authorization = "Bearer " + accessToken;

    return options;
  },
  (error) => {
    return Promise.reject(error);
  }
);

HttpClient.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    exception(error);
    return Promise.reject(error);
  }
);

export default HttpClient;
