import Vue from "vue";

const formatDuration = (duration) => {
  if (!duration || duration === 0) return "00:00";
  return new Date(duration * 1000).toISOString().substring(14, 19);
};

const formatDate = (dateString) => {
  const date = new Date(dateString);

  let year = date.getFullYear();

  let month = date.toLocaleString("en-us", { month: "long" });

  let day = date.getDate().toString().padStart(2, "0");

  return month + " " + day + ", " + year;
};

const formatDateShort = (dateString) => {
  try {
    const date = new Date(dateString);
    return date.toLocaleString("en-US");
  } catch {
    return "formatting error";
  }
};

const secondsToHHMMSS = (seconds) => {
  try {
    return new Date(seconds * 1000).toISOString().substring(11, 19);
  } catch {
    return "formatting error";
  }
};

Vue.filter("formatDuration", formatDuration);
Vue.filter("formatDateShort", formatDateShort);
Vue.filter("secondsToHHMMSS", secondsToHHMMSS);

Vue.filter("formatDate", formatDate);
