import http from "../../services/http";
import Vue from "vue";

const initialState = {
  songs: [],
  songsLoading: false,
};

const state = { ...initialState };

const actions = {
  async getTopSongsAsync({ commit }) {
    try {
      commit("setSongsLoading", true);
      const { data } = await http.get("/songs/top");
      commit("setSongs", data);
    } catch (error) {
      console.log(error);
    } finally {
      commit("setSongsLoading", false);
    }
  },
  async getSongsAsync({ commit }, categoryName) {
    try {
      commit("setSongsLoading", true);
      const songs = await Vue.prototype.$blobClient.listFilesByHierarchyAsync(
        "songs",
        categoryName,
        ""
      );
      console.log(songs[0]);
      commit("setSongs", songs);
    } catch (error) {
      console.log(error);
    } finally {
      commit("setSongsLoading", false);
    }
  },
};

const mutations = {
  setSongs: (state, songs) => (state.songs = songs),
  setSongsLoading: (state, loading) => (state.songsLoading = loading),
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
