<template>
  <div>
    <v-snackbar
      v-model="show"
      v-for="(notification, i) in notifications"
      :key="notification.id"
      bottom
      right
      :color="notification.color"
      :timeout="-1"
      tile
      :style="`margin-bottom: ${i * 60}px`">
      <span class="subtitle-1">{{ notification.message }}</span>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      show: true,
    };
  },
  computed: {
    ...mapGetters("notifications", ["notifications"]),
  },
};
</script>
