import { success, error } from "@/helpers/notificationsHandler";
import http from "../../services/http";
import Vue from "vue";

const initialState = {
  assetKey: {},
  assetKeyLoading: false,
  assets: [],
  assetsLoading: false,
  assetsUploading: [
    // {
    //   name: "file1.jpg",
    //   progress: 0.42,
    //   absolutePath: "https://test.com/100/100.jpg",
    // },
    // {
    //   name: "file1.jpg",
    //   progress: 1,
    //   absolutePath: "https://test.com/200/200.jpg",
    // },
  ],
};

const state = { ...initialState };

const actions = {
  async getAssetKeyAsync({ commit }, id) {
    try {
      commit("assetKeyLoading", true);
      const { data } = await http.get(`/projects/${id}/assets/key`);
      commit("setAssetKey", data);
    } catch {
      error("Could not obtain project asset access key.");
    } finally {
      commit("assetKeyLoading", false);
    }
  },
  async listAssetsFlatAsync({ commit, state }, id) {
    try {
      commit("setAssetsLoading", true);

      const key = state.assetKey.key;

      const assets = await Vue.prototype.$blobClient.listFilesFlatAsync(
        id,
        key
      );

      commit("setAssets", assets);
    } catch {
      error("Failed to load assets.");
    } finally {
      commit("setAssetsLoading", false);
    }
  },
  async listAssetsByHierarchyAsync({ commit, state }, { id, contributor }) {
    try {
      commit("setAssetsLoading", true);

      const key = state.assetKey.key;

      const assets = await Vue.prototype.$blobClient.listFilesByHierarchyAsync(
        id,
        contributor,
        key
      );

      commit("setAssets", assets);
    } catch (error) {
      alert("placeholder listAssetsAsync");
    } finally {
      commit("setAssetsLoading", false);
    }
  },
  async uploadAssetsAsync(
    { state, commit },
    { id, filesWithMetadata, contributor }
  ) {
    const uploadingCallback = (asset, progress) =>
      commit("setAssetsUploading", { asset, progress });

    const uploadedCallback = (asset) => {
      commit("addAsset", asset);
    };

    const key = state.assetKey.key;

    await Vue.prototype.$blobClient.uploadFilesAsync(
      id,
      contributor,
      key,
      filesWithMetadata,
      uploadingCallback,
      uploadedCallback
    );
  },
  async removeAssetsAsync({ commit, state }, { id, assets }) {
    const assetAbsolutePaths = assets.map((x) => x.absolutePath);
    try {
      commit("setAssetsLoading", true);
      await http.patch(
        `/projects/${id}/assets/removeAssets`,
        assetAbsolutePaths
      );

      const newAssetList = state.assets.filter(
        (x) => !assetAbsolutePaths.find((y) => y === x.absolutePath)
      );
      commit("setAssets", newAssetList);
      success(`Removed ${assets.length} assets.`);
    } catch (error) {
      console.log(error);
    } finally {
      commit("setAssetsLoading", false);
    }
  },
  async cancelAssetUploadAsync({ commit }, { absolutePath, fileName }) {
    try {
      Vue.prototype.$blobClient.cancelUpload(fileName);
      commit("setAssetsUploading", {
        asset: {
          absolutePath,
        },
        progress: "Canceled",
      });
    } catch (e) {
      console.log(e);
    }
  },
  clearAssetsUploading({ commit }) {
    commit("clearAssetsUploading");
  },
};

const mutations = {
  setAssetKey: (state, assetKey) => (state.assetKey = assetKey),
  assetKeyLoading: (state, loading) => (state.assetKeyLoading = loading),
  setAssets: (state, assets) => (state.assets = assets),
  setAssetsLoading: (state, loading) => (state.assetsLoading = loading),
  addAsset: (state, asset) => state.assets.push(asset),
  setAssetsUploading: (state, { asset, progress }) => {
    let index = state.assetsUploading.findIndex(
      (x) => x.absolutePath === asset.absolutePath
    );

    if (index === -1) {
      state.assetsUploading.push({
        ...asset,
        progress,
      });
    } else {
      state.assetsUploading[index].progress = progress;
    }
  },
  clearAssetsUploading: (state) => (state.assetsUploading = []),
};

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};
