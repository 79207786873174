import { BlobServiceClient } from "@azure/storage-blob";
import { AbortController } from "@azure/abort-controller";

export default class BlobClient {
  constructor() {
    // this.storageAccountName = "devteststrgacc001";
    // this.storageAccountUrl = "https://devteststrgacc001.blob.core.windows.net/";
    this.storageAccountName = "memorocstgacc";
    this.storageAccountUrl = "https://memorocstgacc.blob.core.windows.net/";
    this.abortControllers = [];
  }

  async uploadFilesAsync(
    container,
    prefix,
    key,
    filesWithMetadata,
    uploadingCallback,
    uploadedCallback
  ) {
    const url = this.storageAccountUrl + container + "?" + key;
    const serviceClient = new BlobServiceClient(url);
    const containerClient = serviceClient.getContainerClient("");

    const promises = [];

    for (const fileWithMetadata of filesWithMetadata) {
      const file = fileWithMetadata.file;
      let metadata = fileWithMetadata.metadata;
      const asset = this.formatAsset(
        container,
        file.name,
        file.type,
        metadata.durationSeconds
      );

      const blockBlobClient = containerClient.getBlockBlobClient(
        (prefix ? `${prefix}/` : "") + file.name
      );

      const abortController = {
        fileName: file.name,
        abortSignal: new AbortController(),
      };
      this.abortControllers.push(abortController);

      promises.push(
        blockBlobClient
          .uploadData(file, {
            blobHTTPHeaders: {
              blobContentType: file.type,
            },
            metadata: this.formatMetadata(metadata),
            onProgress: (p) => {
              const progress = p.loadedBytes / file.size;
              uploadingCallback(asset, progress);
            },
            abortSignal: abortController.abortSignal.signal,
          })
          .then((uploadedResult) => {
            uploadedCallback(asset, uploadedResult);
          })
      );
    }

    await Promise.all(promises);
  }

  cancelUpload(fileName) {
    const index = this.abortControllers.findIndex(
      (f) => f.fileName === fileName
    );

    if (index !== -1) {
      try {
        this.abortControllers[index].abortSignal.abort();
      } finally {
        this.abortControllers.splice(index, 1);
      }
    }
  }

  async listFilesFlatAsync(container, key) {
    const serviceClient = new BlobServiceClient(this.storageAccountUrl + key);
    const containerClient = serviceClient.getContainerClient(container);

    let files = [];

    for await (const blob of containerClient.listBlobsFlat({
      includeMetadata: true,
    })) {
      if (!blob || blob.kind === "prefix") continue;

      let file = {
        absolutePath: `${this.storageAccountUrl}${container}/${blob.name}`,
        contentType: blob.properties.contentType,
      };

      if (blob.metadata) {
        if (blob.metadata.contributor) {
          file["contributor"] = blob.metadata.contributor;
        }
        if (blob.metadata.durationSeconds) {
          file["durationSeconds"] = parseFloat(blob.metadata.durationSeconds);
        }
        if (blob.metadata.segments) {
          file["segments"] = JSON.parse(blob.metadata.segments);
        }
      }

      files.push(file);
    }

    return files;
  }

  async listFilesByHierarchyAsync(container, prefix, key) {
    const serviceClient = new BlobServiceClient(this.storageAccountUrl + key);
    const containerClient = serviceClient.getContainerClient(container);

    let files = [];

    for await (const blob of containerClient.listBlobsByHierarchy("/", {
      prefix: prefix ? `${prefix}/` : "",
      includeMetadata: true,
    })) {
      if (!blob || blob.kind === "prefix") continue;

      let file = {
        absolutePath: `${this.storageAccountUrl}${container}/${blob.name}`,
        contentType: blob.properties.contentType,
      };

      if (blob.metadata) {
        //console.log(blob.metadata);
        if (blob.metadata.contributor) {
          file["contributor"] = blob.metadata.contributor;
        }
        if (blob.metadata.durationSeconds) {
          file["durationSeconds"] = parseFloat(blob.metadata.durationSeconds);
        }
        if (blob.metadata.segments) {
          file["segments"] = JSON.parse(blob.metadata.segments);
        }
      }

      files.push(file);
    }

    return files;
  }

  formatMetadata(metadata) {
    const clonedMetadata = { ...metadata };

    return Object.keys(clonedMetadata).reduce((a, c) => {
      a[c] =
        typeof clonedMetadata[c] !== "string"
          ? JSON.stringify(clonedMetadata[c])
          : clonedMetadata[c];
      return a;
    }, {});
  }

  formatAsset(container, fileName, contentType, durationSeconds) {
    return {
      absolutePath: `${this.storageAccountUrl}${container}/${fileName}`,
      durationSeconds,
      contentType,
      segments: [],
    };
  }
}
